body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root,
.app {
	min-height: 100vh;
}

.app {
    position: relative;
}

.modal-header .modal-title {
    justify-content: center;
    flex: 1;
    display: flex;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.navbar-toggler {
    padding: 0.15em 0.5em;
}

.navbar-toggler-icon {
    width: 1em;
    height: 1em;
}

.home-page,
.details-page,
.listing-page,
.gallery-page,
.leaderboard-page,
.about-page,
#game {
    padding-bottom: 60px;
}

.details-page svg[data-testid="ock-defaultAvatarSVG"],
.details-page svg[data-testid="ock-defaultLoadingSVG"],
.listing-page svg[data-testid="ock-defaultAvatarSVG"],
.listing-page svg[data-testid="ock-defaultLoadingSVG"],
.leaderboard-page svg[data-testid="ock-defaultAvatarSVG"],
.leaderboard-page svg[data-testid="ock-defaultLoadingSVG"],
.bird-identification-modal svg[data-testid="ock-defaultLoadingSVG"] {
	display: none;
}

.bird-identification-modal .ock-text-foreground {
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: inherit;
}

.details-page svg[data-testid="ock-defaultAvatarSVG"] ~ div,
.details-page svg[data-testid="ock-defaultLoadingSVG"] ~ div,
.listing-page svg[data-testid="ock-defaultAvatarSVG"] ~ div,
.listing-page svg[data-testid="ock-defaultLoadingSVG"] ~ div,
.leaderboard-page svg[data-testid="ock-defaultAvatarSVG"] ~ div,
.leaderboard-page svg[data-testid="ock-defaultLoadingSVG"] ~ div {
	margin-left: 0 !important;
}

.form-switch .form-check-input:checked::before {
	background-color: var(--bs-success);
}

.form-switch .form-check-input:not(:checked)::after {
	background-color: #000;
}

.form-switch .form-check-input:checked::after {
	background-color: #fff;
}

.toast-container {
    position: fixed;
}

@media (max-width: 575px) {

    .home-page,
    .details-page,
    .listing-page,
    .gallery-page,
    .leaderboard-page,
    .about-page,
    #game {
        padding-bottom: 85px;
    }

}
