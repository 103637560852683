footer.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #4f46e5;
    color: #fff;
}

footer.footer a,
footer.footer a:hover,
footer.footer a:active,
footer.footer a:focus {
    color: #fff;
}

footer.footer .btn:hover,
footer.footer .btn:focus {
    border-color: #fff;
}

footer.footer i {
    font-size: 1.25rem;
    margin-top: 3px;
}

@media (max-width: 575px) {

    footer.footer {
        height: 85px;
    }

    footer.footer .built-by-text,
    footer.footer .icons-row {
        width: 100%;
    }

}
